import { render, staticRenderFns } from "./talentsApi.vue?vue&type=template&id=eba548bc&scoped=true&"
import script from "./talentsApi.vue?vue&type=script&lang=js&"
export * from "./talentsApi.vue?vue&type=script&lang=js&"
import style0 from "./talentsApi.vue?vue&type=style&index=0&id=eba548bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eba548bc",
  null
  
)

export default component.exports